/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Dharmendra Poonia <dspoonia7@gmail.com>, March 2018
 */

import keyMirror from 'keymirror'

const ActionTypes = keyMirror({
  REQUEST_LOGIN: null,
  RECEIVE_LOGIN: null,
  REQUEST_LOGOUT: null,
  RECEIVE_LOGOUT: null,
  RECEIVE_LOGIN_CREDS: null,

  RECEIVE_GET_ME: null,
  RECEIVE_UPDATE_ME: null,
  RECEIVE_ME_AVATAR_UPDATE: null,

  RECEIVE_GET_ACCOUNTS: null,
  RECEIVE_GET_ACCOUNT: null,
  RECEIVE_DELETE_ACCOUNT: null,

  REQUEST_GET_ACCOUNT_HOTELS: null,
  RECEIVE_GET_ACCOUNT_HOTELS: null,
  RECEIVE_GET_ACCOUNT_HOTEL: null,
  RECEIVE_GET_ACCOUNT_HOTEL_UPDATE: null,
  RECEIVE_UPDATE_HOTEL_LOGO: null,
  RECEIVE_UPDATE_LANDING_PAGE_BACKGROUND_IMAGE: null,
  RECEIVE_DELETE_ACCOUNT_HOTEL: null,

  RECEIVE_GET_CREATE_HOTEL_USERS: null,
  RECEIVE_GET_HOTEL_USERS: null,
  RECEIVE_GET_HOTEL_USER: null,
  RECEIVE_DELETE_HOTEL_USER: null,

  RECEIVE_GET_CREATE_HOTEL_TEAMS: null,
  RECEIVE_GET_HOTEL_TEAMS: null,
  RECEIVE_GET_HOTEL_TEAM: null,
  RECEIVE_DELETE_HOTEL_TEAM: null,
  RECEIVE_POST_TEAM_HOTEL_USER_ADD: null,
  RECEIVE_POST_TEAM_HOTEL_USER_REMOVE: null,
  RECEIVE_GET_HOTEL_FEATURE_FLAGS: null,
  RECEIVE_GET_HOTEL_FEATURE_FLAG_UPDATE: null,

  RECEIVE_GET_HOTEL_REPLY_TEMPLATES: null,
  RECEIVE_GET_HOTEL_REPLY_TEMPLATE: null,
  RECEIVE_DELETE_HOTEL_REPLY_TEMPLATE: null,

  RECEIVE_GET_GUEST_OPT_IN_DETAILS: null,
  RECEIVE_GET_GUEST_OPT_IN_UPDATE: null,

  RECEIVE_GET_HOTEL_PLATFORMS: null,
  RECEIVE_GET_HOTEL_PLATFORM: null,
  RECEIVE_DELETE_HOTEL_PLATFORM: null,
  RECEIVE_UPLOAD_HOTEL_PLATFORM_QR_CODE: null,

  RECEIVE_GET_HOTEL_INTEGRATIONS: null,
  RECEIVE_GET_HOTEL_INTEGRATION: null,
  RECEIVE_DELETE_HOTEL_INTEGRATION: null,

  REQUEST_SET_LOADING_STATE: null,
  RECEIVE_LOADING_HOTEL_USERS: null,

  RECEIVE_GET_ADMIN_USERS: null,
  RECEIVE_GET_ADMIN_USER: null,
  RECEIVE_DELETE_ADMIN_USER: null,
  RECEIVE_UPDATE_USER_ROLE: null,
  RECEIVE_UPDATE_USER_PASSWORD: null,

  RECEIVE_GET_BUSINESS_TYPES: null,
  RECEIVE_GET_BUSINESS_TYPE: null,
  RECEIVE_GET_FEATURE_FLAGS: null,
  RECEIVE_PERMISSIONS: null,

  RECEIVE_GET_HOTEL_TIMEZONES: null,

  RECEIVE_GET_HOTEL_QUICK_REPLIES: null,
  RECEIVE_GET_HOTEL_QUICK_REPLY: null,
  RECEIVE_DELETE_HOTEL_QUICK_REPLY: null,

  RECEIVE_GET_HOTEL_VERIFY_INTEGRATION: null,
  RECEIVE_GET_HOTEL_VERIFY_INTEGRATIONS: null,
  RECEIVE_GET_HOTEL_VERIFY_INTEGRATION_STATUS: null,

  RECEIVE_GET_HOTEL_WIDGETS: null,
  RECEIVE_GET_HOTEL_WIDGET: null,

  RECEIVE_GET_HOTEL_NPS_DETAILS: null,
  RECEIVE_CREATE_HOTEL_NPS_DETAILS: null,

  RECEIVE_GET_TAGS: null,
  RECEIVE_GET_BRAND_TAGS: null,
  RECEIVE_GET_BRAND_TAG: null,
  RECEIVE_BRAND_TAG_DELETE: null,

  RECEIVE_POST_WEBHOOK: null,

  RECEIVE_GET_HOTEL_SUB_PROPERTIES: null,
  RECEIVE_GET_HOTEL_SUB_PROPERTY: null,
  RECEIVE_UPDATE_HOTEL_SUB_PROPERTY_LOGO: null,

  RECEIVE_GET_SALUTATION: null,
  RECEIVE_GET_SALUTATIONS: null,

  RECEIVE_GET_HOTEL_SALUTATIONS: null,
  RECEIVE_POST_HOTEL_SALUTATIONS: null,
  RECEIVE_UPDATE_HOTEL_SALUTATIONS: null,

  RECEIVE_KNOWCROSS_CREDENTIALS_STATUS: null,
  RECEIVE_SHIJI_CREDENTIALS_STATUS: null
})

export default ActionTypes
