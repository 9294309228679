/**
 * Copyright (C) Glowing.io - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Nitin Verma <nitinvupk83@gmail.com>, June 2021
 */

import { fromJS, OrderedMap } from 'immutable'

import ActionTypes from '../constants/ActionTypes'

const {
  RECEIVE_GET_HOTEL_VERIFY_INTEGRATION,
  RECEIVE_GET_HOTEL_VERIFY_INTEGRATIONS,
  RECEIVE_KNOWCROSS_CREDENTIALS_STATUS,
  RECEIVE_SHIJI_CREDENTIALS_STATUS
} = ActionTypes

const initialState = new OrderedMap()

export default (state = initialState, action) => {
  const { type, data } = action
  switch (type) {
    case RECEIVE_GET_HOTEL_VERIFY_INTEGRATIONS:
      return new OrderedMap(
        data.integration_statuses.map(integration => [integration.id, fromJS(integration)])
      )

    case RECEIVE_GET_HOTEL_VERIFY_INTEGRATION:
      return state.set(data.integration_status.id, data.integration_status)

    case RECEIVE_KNOWCROSS_CREDENTIALS_STATUS:
      return state.set(data)

    case RECEIVE_SHIJI_CREDENTIALS_STATUS:
      return state.set(data)

    default:
      return state
  }
}
